import React, { Suspense, useState, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import parse from 'html-react-parser';
import _ from 'lodash';
import { memo } from 'react';
import { useMemo } from 'react';

export function createResource(promise) {
    let status = 'pending';
    let result;
    let suspender = promise.then(
      r => {
        status = 'success';
        result = r;
      },
      e => {
        status = 'error';
        result = e;
      }
    );
  
    return {
      read() {
        if (status === 'pending') {
          throw suspender;
        } else if (status === 'error') {
          return {status: 'error', result: result};
        } else {
          return {status: 'success', result: result};
        }
      }
    };
  }

export const setServerClientData = (id, item, index) => {
    if(index == undefined){
       return  window.serverResponseStore[id] = item
    }
    else{
        return  window.serverResponseStore[id][index]
    }
}

export const getServerClientData = (id, index) => {
    if(index == undefined){
       return  window.serverResponseStore[id]
    }
    else{
        return  window.serverResponseStore[id][index]
    }
}


export const defaultFetchMissingData = async (apiPathway, pathway, id, data) => {
    let server = process.env.SSR_SERVER == undefined ? window.location.origin : process.env.SSR_SERVER
    const response = await fetch(`${server}${apiPathway}`, {
        method:'POST',
        credentials: 'include',
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({pathway: pathway, id:id, data:data})
    })
    const results = await response.json()
    window.serverResponseStore[id] = results
    return results
}


export const serverHtmlAttributes = (attributesObject) => {
    let newTags = {};

    // Server-side logic
    if (typeof window === 'undefined') {
        // Create a 'data-server-attributes' attribute with JSON string
        newTags['data-server-attributes'] = JSON.stringify(attributesObject);
    } else {
        // Client-side logic
        // Assuming window.serverResponseStore is already populated with the server data
        if(window.serverResponseStore == undefined){
            window.serverResponseStore = {}
        }
        for (const [attr, valuePath] of Object.entries(attributesObject)) {
            let path = valuePath.split('.')
            const id = path.shift()
            path = path.join('.')
            if(Array.isArray(window.serverResponseStore[id])){
                const index = window.serverResponseStore.serverLoopServerCallIndexTracker[id]
                if (window.serverResponseStore[id][index] && _.get(window.serverResponseStore[id][index], path)) {
                    newTags[attr] = _.get(window.serverResponseStore[id][index], path);
                } 
            }
            else{
                if (window.serverResponseStore[id] && _.get(window.serverResponseStore[id], path)) {
                    newTags[attr] = _.get(window.serverResponseStore[id], path);
                } 
            }
        }
    }

    return newTags;
};




export const ServerCallHandler = memo(({children, id, pathway,  data = null, fetchHandler, dangerouslySetHtml, overwrite, index, errorHandler, fallback = <></>}) => {    

    const cleanPathway = String(pathway)
    const cleanData = JSON.stringify(data)
    const cleanId = String(id)

    const resource = useMemo(() => {
        if(children){
            if(typeof window != 'undefined'){
                if(window.serverResponseStore == undefined){
                    window.serverResponseStore = {}
                }
                const serverData =  window.serverResponseStore[id];
                if(serverData == undefined && pathway != undefined){
                    return createResource(defaultFetchMissingData(fetchHandler, pathway, id, data));
                }
            }
        }
        else{
            if(typeof window != 'undefined'){
                if(overwrite != undefined){
                    let overwriteId = null
                    let overwriteKey = null
                    let serverData = null
                    if(overwrite.includes('.')){
                        const split = overwrite.split('.');
                        overwriteId = split[0]
                        overwriteKey = split[1]
                    } 
                    else{
                        overwriteId = overwrite
                    }
    
                    if(Array.isArray(window.serverResponseStore[overwriteId])){
                        serverData = window.serverResponseStore[overwriteId][index];
                    }
                    else{
                        serverData = window.serverResponseStore[overwriteId];
                    }
    
                  
                     
                    if(serverData != undefined && overwriteKey != null){
                        serverData = serverData[overwriteKey]
                    }


                    if(serverData == undefined){
                        return createResource(defaultFetchMissingData(fetchHandler, pathway, id, data));
                    }
                }
                else{
                    const serverData = window.serverResponseStore[id];
                    let resource
                    if(serverData == undefined){
                        return createResource(defaultFetchMissingData(fetchHandler, pathway, id, data));
                    }
                }
            }
        }
    }, [cleanPathway, cleanData, cleanId, fetchHandler, overwrite])

    if(children){
            if(typeof window == 'undefined'){
                return(
                    React.createElement('servercall', {
                        pathway:cleanPathway,
                        data:cleanData,
                        id:cleanId
                    },
                        children()
                    )
                )
            }
            else{
                const serverData =  window.serverResponseStore[id];
                const SuspenseWrapper = ({serverDataOld}) => {
                    let serverData
                    if(serverDataOld == undefined){
                        let result = resource.read()
                        if(result.status == 'error'){
                            if(errorHandler != undefined){
                                return errorHandler(result.result)
                            }
                            return <></>    
                        }
                        else{
                            serverData = result.result
                        }
                    }
                    else{
                        serverData = serverDataOld
                    }

                    return children(serverData)
                };
                
                return (
                    (serverData != undefined) ?
                    children(serverData)
                    :
                    <Suspense fallback={fallback}>
                        <SuspenseWrapper serverDataOld={serverData}/>
                    </Suspense>
                );

            }
    }
    else{
        return (
            (typeof window == 'undefined') ?
                React.createElement('servercall', {
                    pathway:cleanPathway,
                    data:cleanData,
                    id:cleanId,
                    overwrite:overwrite || 'true',
                    dangerouslysethtml:dangerouslySetHtml == true ? 'true' : 'false'
                })
                
            :
                (() => {
                    if(window.serverResponseStore == undefined){
                        window.serverResponseStore = {}
                    }
                        if(overwrite != undefined){
                               let overwriteId = null
                                let overwriteKey = null
                                let serverData = null
                                if(overwrite.includes('.')){
                                    const split = overwrite.split('.');
                                    overwriteId = split[0]
                                    overwriteKey = split[1]
                                } 
                                else{
                                    overwriteId = overwrite
                                }
                
                                if(Array.isArray(window.serverResponseStore[overwriteId])){
                                    serverData = window.serverResponseStore[overwriteId][index];
                                }
                                else{
                                    serverData = window.serverResponseStore[overwriteId];
                                }
                
                            
                                
                                if(serverData != undefined && overwriteKey != null){
                                    serverData = serverData[overwriteKey]
                                }

                            const renderOuterHtml = (data) => {
                                try {
                                    if(dangerouslySetHtml == true){
                                        return parse(data)
                                    }
                                    else{
                                        return String(data)
                                    }
                                   
                                } catch (error) {
                                    return String(data)
                                }
                            }
                            const SuspenseWrapper = ({serverDataOld}) => {
                                let serverData
                                if(serverDataOld == undefined){
                                    let result = resource.read()
                                    if(result.status == 'error'){
                                        if(errorHandler != undefined){
                                            return errorHandler(result.result)
                                        }
                                        return <></>
                                    }
                                    else{
                                        serverData = result.result
                                    }
                                }
                                else{
                                    serverData = serverDataOld
                                }
    
                                if(Array.isArray(window.serverResponseStore[overwriteId])){
                                    serverData = window.serverResponseStore[overwriteId][index];
                                }
                                else{
                                    serverData = window.serverResponseStore[overwriteId];
                                }
                                return renderOuterHtml(serverData)
                            };

                            return(
                                serverData != undefined ?
                                    renderOuterHtml(serverData)
                                :
                                <Suspense fallback={fallback}>
                                    <SuspenseWrapper serverDataOld={serverData}/>
                                </Suspense>

                            )
                        }
                        else{
                           
                                const serverData = window.serverResponseStore[id];
    
                                const renderOuterHtml = (serverData) => {
                                    try {
                                        return parse(serverData)
                                    } catch (error) {
                                        return String(serverData)
                                    }
                                }
                                const SuspenseWrapper = ({serverDataOld}) => {
                                    let serverData
                                    if(serverDataOld == undefined){
                                        let result = resource.read()
                                        if(result.status == 'error'){
                                            if(errorHandler != undefined){
                                                return errorHandler(result.result)
                                            }
                                            return <></>
                                        }
                                        else{
                                            serverData = result.result
                                        }
                                    }
                                    else{
                                        serverData = serverDataOld
                                    }
                                    return renderOuterHtml(serverData)
                                };
    
                                return(
                                    serverData != undefined ?
                                        renderOuterHtml()
                                    :
                                    <Suspense fallback={fallback}>
                                        <SuspenseWrapper serverDataOld={serverData}/>
                                    </Suspense>
    
                                )
                            }
              
                        
                        
                })()
        );
    }   
});



export const LoopServerCall =  memo(({ children, id, pathway,  data = null , fetchHandler, fetchOption, errorHandler, fallback = <></>}) => {
    
   
    const cleanPathway = String(pathway);
    const cleanData = JSON.stringify(data);
    const cleanId = String(id);

    if (typeof window === 'undefined') {
        // Server-side rendering
        return React.createElement('loopservercall', {
            pathway: cleanPathway,
            data: cleanData,
            id: cleanId
        }, children());
    } else {
        // Client-side rendering
        if(window.serverResponseStore == undefined){
            window.serverResponseStore = {}
        }
        if(window.serverResponseStore.serverLoopServerCallIndexTracker == undefined){
            window.serverResponseStore.serverLoopServerCallIndexTracker = {}

        }
        window.serverResponseStore.serverLoopServerCallIndexTracker[id] = 0
        const loopData = window.serverResponseStore[id];
        let resource
        if(loopData == undefined){
            resource = createResource(defaultFetchMissingData(fetchHandler, pathway, id, data));
        }

        const renderChildren = (loopData) => {
            let resultChildren = []
            for(let data of loopData){
                resultChildren.push(children(data, window.serverResponseStore.serverLoopServerCallIndexTracker[id]))
                window.serverResponseStore.serverLoopServerCallIndexTracker[id] += 1
            }
            return resultChildren
        }

        const SuspenseWrapper = ({loopDataOld}) => {
            let loopData
            if(loopDataOld == undefined){
                let result = resource.read()
                if(result.status == 'error'){
                    if(errorHandler != undefined){
                        return errorHandler(result.result)
                    }
                    return <></>
                }
                else{
                    loopData = result.result
                }
            }
            else{
                loopData = loopDataOld
            }
            return renderChildren(loopData)
        };
       

        return (
            loopData != undefined ?
                renderChildren(loopData)
            :
            <Suspense fallback={fallback}>
              <SuspenseWrapper loopDataOld={loopData}/>
            </Suspense>
          );

       
    }
});



let metaTagCounter = 0


export const MetaTagHandler = ({children, id = 'metaTagHandler', pathway, data = null, fetchHandler}) => {    


    const cleanPathway = String(pathway)
    const cleanData = JSON.stringify(data)
    const cleanId = String(id)
    const resource = useMemo(() => {
        if(children){
            if(typeof window != 'undefined'){
                if(window.serverResponseStore == undefined){
                    window.serverResponseStore = {}
                }
                const serverData =  window.serverResponseStore[id];
                if(serverData == undefined && pathway != undefined){
                    return createResource(defaultFetchMissingData(fetchHandler, pathway, id, data));
                }
            }
        }
    }, [cleanPathway, cleanData, cleanId, fetchHandler])



        if(children){
                if(typeof window == 'undefined'){
                    metaTagCounter += 1
                    try {
                        
                        return(
                            
                            React.createElement('metataghandler', {
                                pathway:cleanPathway,
                                data:cleanData,
                                id:cleanId,
                                metatagcounter:metaTagCounter
                            },
                                children()
                            )
                        )
                    } catch (error) {
                        //Silence error
                    }
                    
                }
                else{
                    const serverData =  window.serverResponseStore[id];

                    const renderOuterHtml = () => {
                        const htmlString = ReactDOMServer.renderToString(children())
                        const domParser = new DOMParser()
                        const root = domParser.parseFromString(`<html><head>${htmlString}</head></html>`, 'text/html')
                        root.head.querySelectorAll('*').forEach(element => {
                            element.setAttribute('data-server-rendered', 'true')
                            if(element.hasAttribute('text')){
                                element.innerText = element.getAttribute('text')
                                element.removeAttribute('text')
                            }
                        })
                        document.head.querySelectorAll('[data-server-rendered]').forEach(element => element.remove())
                        document.head.insertAdjacentHTML('afterbegin', root.head.innerHTML)
                    }

                    const SuspenseWrapper = ({serverDataOld}) => {
                        if(pathway != undefined){
                            const serverData = serverDataOld == undefined ? resource.read() : serverDataOld;
                        } 
                    renderOuterHtml()
                    };
                    
                    return (
                        serverData != undefined || pathway == undefined ?
                            renderOuterHtml()
                        :
                        <Suspense fallback={<></>}>
                            <SuspenseWrapper serverDataOld={serverData}/>
                        </Suspense>

                    );

                }
        } 
};


export const ClientOnly = memo(({ children, fallback=<></>}) => {
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        setIsMounted(true);
    }, []);

   
    return isMounted ? <>{children()}</> : fallback;
});


