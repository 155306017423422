import { useSelector } from "react-redux"
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";

import { Popover, Text, Button } from '@mantine/core';
import ReactGA from "react-ga4";


const Footer = () => {
    const currentVersion = useSelector(state => state.mainapp.versionSlice.versions.currentVersion)
    const [opened, setOpened] = useState(false)
    const isWindows = useSelector(state => state.mainapp.osTypeSlice.isWindows)
    const [coppied, setCoppied] = useState(false)


    return (
        <>
            <footer className="footer">
                <div className="footer-content">
                    <div className="footer-promo">
                        <h1>
                            Download Frigid Client
                        </h1>
                        <p>Explore the Frigid Network, Build Apps, Stay Secure and Anonymous.</p>

                        <Popover
                            opened={opened}
                            onChange={setOpened}
                            width={
                                isWindows ? 582 : 332
                            }
                            position='right-end'
                            shadow="md"
                            offset={20}
                        >
                            <Popover.Target>
                                <button
                                    onClick={() => setOpened(!opened)}
                                    style={{ width: 'fit-content' }}
                                    className="hero-btn"
                                >
                                    {isWindows ? (
                                        <>
                                            <img style={{ width: '30px !important' }} src="/assets/windows.png" alt="Windows" />
                                            Download Windows App
                                        </>
                                    ) : (
                                        <>
                                            <img src="/assets/applelogo.png" alt="Apple" />
                                            Download Mac App
                                        </>
                                    )}
                                </button>
                            </Popover.Target>
                            <Popover.Dropdown>
                                {/* If Windows user, show Windows instructions, else show Mac downloads */}
                                {isWindows ? (
                                    <div className='browser-options'>
                                        <div className="browser-title">
                                            <p>
                                                <strong>Run This Command in CMD or Terminal</strong>
                                            </p>
                                            <button onClick={() => {
                                                ReactGA.event({
                                                    category: "Download",
                                                    action: "Downloaded Frigid Client",
                                                    label: "Landing Page - Windows",
                                                });
                                                navigator.clipboard.writeText(`curl -o frigid-${currentVersion}.exe "https://frigidweb.com/download/windows/frigid?version=${currentVersion}" && start frigid-${currentVersion}.exe`)


                                                setCoppied(true)
                                            }}>
                                                <i className="material-icons">
                                                    {
                                                        coppied ? 'check_circle' : 'content_copy'
                                                    }
                                                </i>
                                                {
                                                    coppied ? 'Copied Successfully' : 'Copy to Clipboard'
                                                }
                                            </button>
                                            {/*    <p style={{ fontSize: '0.9rem' }}>
                                        You can download Frigid by running the following
                                        <code> curl </code> command in your command prompt or terminal.
                                    </p> */}


                                        </div>
                                        <pre style={{ whiteSpace: 'pre-wrap' }}>
                                            {`curl -o frigid-${currentVersion}.exe "https://frigidweb.com/download/windows/frigid?version=${currentVersion}" && start frigid-${currentVersion}.exe`}
                                        </pre>

                                    </div>
                                ) : (
                                    <div className='browser-options'>
                                        <a
                                            href={
                                                'https://frigid-bucket.sgp1.cdn.digitaloceanspaces.com/production/Frigid-'
                                                + currentVersion + '-arm64.dmg'
                                            }
                                            onClick={() => {
                                                ReactGA.event({
                                                    category: "Download",
                                                    action: "Downloaded Frigid Client",
                                                    label: "Landing Page - Apple Silicon Mac",
                                                });
                                            }}
                                            download
                                        >
                                            <div className="menu-text">
                                                <img src={'/assets/applelogo.png'} alt="Apple" />
                                                <p>Apple Silicon Mac  <br />
                                                    <span>
                                                        For M1+ Macs
                                                    </span>
                                                </p>
                                            </div>
                                            <i className="material-icons">
                                                get_app
                                            </i>
                                        </a>

                                        {/* <a
                                            href={
                                                'https://frigid-bucket.sgp1.cdn.digitaloceanspaces.com/production/Frigid-'
                                                + currentVersion + '.dmg'
                                            }
                                            onClick={() => {
                                                ReactGA.event({
                                                    category: "Download",
                                                    action: "Downloaded Frigid Client",
                                                    label: "Landing Page - Intel Mac",
                                                });
                                            }}
                                            download
                                        >
                                            <div className="menu-text">
                                                <img src={'/assets/intel.png'} alt="Intel" />
                                                <p>Intel Mac
                                                    <br />
                                                    <span>
                                                        More Common Macs
                                                    </span>
                                                </p>
                                            </div>
                                            <i className="material-icons">
                                                get_app
                                            </i>
                                        </a> */}
                                    </div>
                                )}
                            </Popover.Dropdown>
                        </Popover>


                    </div>
                    <div className="footer-links">

                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/developer">Developer</Link></li>
                            <li><Link to="/community">Community</Link></li>

                            {/*       
                            <li><Link to="/about">About</Link></li> */}
                        </ul>
                    </div>
                </div>
                <div className="footer-footer">

                </div>
            </footer>

        </>
    )
}

export default Footer