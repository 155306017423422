import { useSelector } from "react-redux"
import { Link } from "react-router-dom";
import { useNavigate } from "../../node_modules/react-router-dom/dist/index";
import { useEffect } from "react";
import { LoopServerCall, MetaTagHandler, ServerCallHandler, serverHtmlAttributes } from "../../library/client/serverCallHandler";
import NonLodable from "./nonLodable";
import Footer from "./components/footer";
import ReactGA from "react-ga4";
import { useRef } from "react";
import { Popover, Text, Button } from '@mantine/core';
import { useState } from "react";

const useIntersectionObserver = (callback, options) => {
    const targetRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            if (callback) {
                callback(entry);
            }
        }, options);

        if (targetRef.current) {
            observer.observe(targetRef.current);
        }

        return () => {
            if (targetRef.current) {
                observer.unobserve(targetRef.current);
            }
        };
    }, [targetRef, callback, options]);

    return targetRef;
};

const VideoBlock = ({ src, title, description, align = "left" }) => {
    const videoRef = useIntersectionObserver(
        (entry) => {
            if (entry.isIntersecting) {
                videoRef.current.play();
            } else {
                videoRef.current.pause();
            }
        },
        { threshold: 0.7 }
    );

    return (
        <div className={`video-block ${align === "right" ? "video-block-right" : "video-block-left"}`}>
            {align === "left" ? (
                <>
                    <video ref={videoRef} loop muted>
                        <source src={src} type="video/mp4" />
                    </video>
                    <div className="video-block-text">
                        <h2>{title}</h2>
                        <p>{description}</p>
                    </div>
                </>
            ) : (
                <>
                    <div className="video-block-text">
                        <h2>{title}</h2>
                        <p>{description}</p>
                    </div>
                    <video ref={videoRef} loop muted>
                        <source src={src} type="video/mp4" />
                    </video>
                </>
            )}
        </div>
    );
};

const LandingPage = () => {
    const chainList = useSelector(state => state.mainapp.chainSlice.chainList)
    const currentVersion = useSelector(state => state.mainapp.versionSlice.versions.currentVersion)
    const [coppied, setCoppied] = useState(false)

    const isWindows = useSelector(state => state.mainapp.osTypeSlice.isWindows)
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [opened, setOpened] = useState(false)

    return (
        <>
            <MetaTagHandler>
                {() => (
                    <>
                        <title>
                            Frigid - Explore & Build Apps Hosted on the Blockchain
                        </title>
                        <meta
                            name="description"
                            content="Explore and build dApps with confidence without revealing your identity. By Leveraging blockchain technology, Frigid hosts all applications without centralized servers, offering unparalleled security and anonymity."
                        />
                        <meta
                            name="keywords"
                            content="Frigid, Blockchain, dApps, Decentralized, Hosting, Domain, Name, System, DNS, ERC20"
                        />
                    </>
                )}
            </MetaTagHandler>

            <div className="hero-wrapper">
                <div className="hero">
                    <h1>
                        Explore & Build Apps <br /> Hosted on the Blockchain
                    </h1>
                    <p>
                        Download Frigid Network Client and explore apps and <br /> websites hosted entirely on the blockchain.
                    </p>

                    {/* 
                      Popover for BOTH Windows and Mac. 
                      We conditionally show different content depending on the detected OS.
                    */}
                    <Popover
                        opened={opened}
                        onChange={setOpened}
                        width={
                            isWindows ? 582 : 332
                        }
                        position="bottom-center"
                        shadow="md"
                        offset={20}
                    >
                        <Popover.Target>
                            <button
                                onClick={() => setOpened(!opened)}
                                style={{ width: 'fit-content' }}
                                className="hero-btn"
                            >
                                {isWindows ? (
                                    <>
                                        <img style={{ width: '30px !important' }} src="/assets/windows.png" alt="Windows" />
                                        Download Windows App
                                    </>
                                ) : (
                                    <>
                                        <img src="/assets/applelogo.png" alt="Apple" />
                                        Download Mac App
                                    </>
                                )}
                            </button>
                        </Popover.Target>
                        <Popover.Dropdown>
                            {/* If Windows user, show Windows instructions, else show Mac downloads */}
                            {isWindows ? (
                                <div className='browser-options'>
                                    <div className="browser-title">
                                        <p>
                                            <strong>Run This Command in CMD or Terminal</strong>
                                        </p>
                                        <button onClick={() => {
                                            ReactGA.event({
                                                category: "Download",
                                                action: "Downloaded Frigid Client",
                                                label: "Landing Page - Windows",
                                            });
                                            // copy to clipboard
                                            navigator.clipboard.writeText(`curl -o frigid-${currentVersion}.exe "https://frigidweb.com/download/windows/frigid?version=${currentVersion}" && start frigid-${currentVersion}.exe`)

                                            setCoppied(true)
                                        }}>
                                            <i className="material-icons">
                                                {
                                                    coppied ? 'check_circle' : 'content_copy'
                                                }
                                            </i>
                                            {
                                                coppied ? 'Copied Successfully' : 'Copy to Clipboard'
                                            }
                                        </button>
                                        {/*    <p style={{ fontSize: '0.9rem' }}>
                                        You can download Frigid by running the following
                                        <code> curl </code> command in your command prompt or terminal.
                                    </p> */}


                                    </div>
                                    <pre style={{ whiteSpace: 'pre-wrap' }}>
                                        {`curl -o frigid-${currentVersion}.exe "https://frigidweb.com/download/windows/frigid?version=${currentVersion}" && start frigid-${currentVersion}.exe`}
                                    </pre>

                                </div>
                            ) : (
                                <div className='browser-options'>
                                    <a
                                        href={
                                            'https://frigid-bucket.sgp1.cdn.digitaloceanspaces.com/production/Frigid-'
                                            + currentVersion + '-arm64.dmg'
                                        }
                                        onClick={() => {
                                            ReactGA.event({
                                                category: "Download",
                                                action: "Downloaded Frigid Client",
                                                label: "Landing Page - Apple Silicon Mac",
                                            });
                                        }}
                                        download
                                    >
                                        <div className="menu-text">
                                            <img src={'/assets/applelogo.png'} alt="Apple" />
                                            <p>Apple Silicon Mac  <br />
                                                <span>
                                                    For M1+ Macs
                                                </span>
                                            </p>
                                        </div>
                                        <i className="material-icons">
                                            get_app
                                        </i>
                                    </a>

                                    {/* <a
                                        href={
                                            'https://frigid-bucket.sgp1.cdn.digitaloceanspaces.com/production/Frigid-'
                                            + currentVersion + '.dmg'
                                        }
                                        onClick={() => {
                                            ReactGA.event({
                                                category: "Download",
                                                action: "Downloaded Frigid Client",
                                                label: "Landing Page - Intel Mac",
                                            });
                                        }}
                                        download
                                    >
                                        <div className="menu-text">
                                            <img src={'/assets/intel.png'} alt="Intel" />
                                            <p>Intel Mac
                                                <br />
                                                <span>
                                                    More Common Macs
                                                </span>
                                            </p>
                                        </div>
                                        <i className="material-icons">
                                            get_app
                                        </i>
                                    </a> */}
                                </div>
                            )}
                        </Popover.Dropdown>
                    </Popover>


                    <span>
                        {isWindows
                            ? <Popover position="bottom-center" width={300} shadow="md" offset={20}>
                                <Popover.Target>
                                    <p style={{
                                        cursor: 'pointer',
                                    }}>Download for MacOS Systems</p>
                                </Popover.Target>

                                <Popover.Dropdown>
                                    <div className='browser-options'>
                                        <a
                                            href={
                                                'https://frigid-bucket.sgp1.cdn.digitaloceanspaces.com/production/Frigid-'
                                                + currentVersion + '-arm64.dmg'
                                            }
                                            onClick={() => {
                                                ReactGA.event({
                                                    category: "Download",
                                                    action: "Downloaded Frigid Client",
                                                    label: "Landing Page - Apple Silicon Mac",
                                                });
                                            }}
                                            download
                                        >
                                            <div className="menu-text">
                                                <img src={'/assets/applelogo.png'} alt="Apple" />
                                                <p>Apple Silicon Mac  <br />
                                                    <span>
                                                        For M1+ Macs
                                                    </span>
                                                </p>
                                            </div>
                                            <i className="material-icons">
                                                get_app
                                            </i>
                                        </a>

                                        {/* <a
                                            href={
                                                'https://frigid-bucket.sgp1.cdn.digitaloceanspaces.com/production/Frigid-'
                                                + currentVersion + '.dmg'
                                            }
                                            onClick={() => {
                                                ReactGA.event({
                                                    category: "Download",
                                                    action: "Downloaded Frigid Client",
                                                    label: "Landing Page - Intel Mac",
                                                });
                                            }}
                                            download
                                        >
                                            <div className="menu-text">
                                                <img src={'/assets/intel.png'} alt="Intel" />
                                                <p>Intel Mac
                                                    <br />
                                                    <span>
                                                        More Common Macs
                                                    </span>
                                                </p>
                                            </div>
                                            <i className="material-icons">
                                                get_app
                                            </i>
                                        </a> */}
                                    </div>
                                </Popover.Dropdown>
                            </Popover>


                            : <Popover position="bottom-center" width={582} shadow="md" offset={20}>
                                <Popover.Target>
                                    <p style={{
                                        cursor: 'pointer',
                                    }}>Download for Windows Systems</p>
                                </Popover.Target>

                                <Popover.Dropdown>
                                    <div className='browser-options'>
                                        <div className="browser-title">
                                            <p>
                                                <strong>Run This Command in CMD or Terminal</strong>
                                            </p>
                                            <button onClick={() => {
                                                ReactGA.event({
                                                    category: "Download",
                                                    action: "Downloaded Frigid Client",
                                                    label: "Landing Page - Windows",
                                                });
                                                // copy to clipboard
                                                navigator.clipboard.writeText(`curl -o frigid-${currentVersion}.exe "https://frigidweb.com/download/windows/frigid?version=${currentVersion}" && start frigid-${currentVersion}.exe`)

                                                setCoppied(true)
                                            }}>
                                                <i className="material-icons">
                                                    {
                                                        coppied ? 'check_circle' : 'content_copy'
                                                    }
                                                </i>
                                                {
                                                    coppied ? 'Copied Successfully' : 'Copy to Clipboard'
                                                }
                                            </button>
                                            {/*    <p style={{ fontSize: '0.9rem' }}>
                                        You can download Frigid by running the following
                                        <code> curl </code> command in your command prompt or terminal.
                                    </p> */}
                                        </div>
                                        <pre style={{ whiteSpace: 'pre-wrap' }}>
                                            {`curl -o frigid-${currentVersion}.exe "https://frigidweb.com/download/windows/frigid?version=${currentVersion}" && start frigid-${currentVersion}.exe`}







                                        </pre>
                                    </div>
                                </Popover.Dropdown>
                            </Popover>
                        }
                    </span>
                </div>
            </div>

            <div className="video-block-grouper">
                <VideoBlock
                    src="/assets/video/Frigid-App-Clip.mp4"
                    title="Browse Anonymously"
                    description="With Frigids proxy technology you can use any web browser to access apps built on the Frigid Network. So support for major browsers like Chrome, Edge, Brave, and more."
                    align="left"
                />
                <VideoBlock
                    src="/assets/video/Frigid-Developer-Clip.mp4"
                    title="Host Freely"
                    description="Frigid stores things like domain names, website data, and more on the blockchain. This means that your data is secure and can be accessed from anywhere in the world."
                    align="right"
                />
            </div >

            <div className="feature-wrapper">
                <h1>Stay Anonymous and Secure</h1>
                <p>
                    Explore and build dApps with confidence without revealing your identity. By Leveraging blockchain technology, Frigid hosts all applications without centralized servers, offering unparalleled security and anonymity.
                </p>
            </div>

            <div className="feature-block-grouper">
                <div className="feature-blocks">
                    <div className="feature-block">
                        <img src="/assets/globe.svg" alt="globe" />
                        <h2>Censorship Free Apps</h2>
                        <p>
                            Whether you're hosting a news publication free of censorship or building the next DApp platform, Frigid allows for maximum flexibility and anonymity when deploying.
                        </p>
                    </div>

                    <div className="feature-block">
                        <img src="/assets/blockchain.svg" alt="blockchain" />
                        <h2>All Data Stored on The Blockchain</h2>
                        <p>
                            Frigid stores things like domain names, website data, and more on the blockchain. This means that your data is secure and can be accessed from anywhere in the world.
                        </p>
                    </div>
                </div>
            </div>

            <div className="feature-wrapper">
                <h1>Massive Network Support</h1>
                <p>
                    Frigid supports many (ERC20) networks to maximize your experience no matter what blockchain technology you prefer. Up to 7 networks are supported for domain purchases, website hosting, and browsing. Build and explore today!
                </p>
            </div>

            <div className="feature-chips">
                {chainList.map((chain, index) => {
                    return (
                        <div className="feature-chip" key={index}>
                            <div className="chip-content">
                                <img src={"/assets/chains/" + chain.image} alt={chain.name} />
                                <span>{chain.name}</span>
                            </div>
                        </div>
                    );
                })}
            </div>

            <Footer />
        </>
    );
}

export default LandingPage;
