import { useSelector } from "react-redux"
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import ReactGA from "react-ga4";


const Navbar = () => {
    const navigate = useNavigate()
    const currentVersion = useSelector(state => state.mainapp.versionSlice.versions.currentVersion)


    useEffect(() => {
        const navbar = document.querySelector('.navbar')
        window.addEventListener('scroll', () => {
            if (window.scrollY > 0) {
                navbar.classList.add('scrolled-nav')
            } else {
                navbar.classList.remove('scrolled-nav')
            }
        })
    }, [])

    return (
        <>

            {/* <MetaTagHandler >
                {() => (
                    <>
                        <title>Poodle</title>
                    </>
                )}
            </MetaTagHandler>  */}
            {/* <NonLodable /> */}

            {/* <h2><ServerCallHandler id={"getDogHusky"} pathway={'getDog'} data={{breed:'husky'}} fetchHandler={'/api'} overwrite={'getDogHusky.src'} /></h2> */}


            {/*  <LoopServerCall id="getMultipleDogs" pathway={'getDog'} data={[{breed:'husky'}, {breed:'poodle'}]} fetchHandler={'/api'} >
                {() => (
                    <>
                        <img {...serverHtmlAttributes({src: "getMultipleDogs.src"})} />
                    </>
                )}




                    
                   
            </LoopServerCall> */}

            <nav className="navbar">
                <Link to="/" className="logo">  <img onClick={
                    () => {
                        navigate('/')
                    }
                } src={'/assets/frigid.svg'} alt="frigid logo" /></Link>
                <ul className="nav-links desktop-navbar">
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/developer">Developer</Link></li>
                    <li><Link to="/community">Community</Link></li>

                    <li>
                        <a href="https://github.com/Frigid-Web/frigid-opensource" target="_blank">Open Source</a>
                    </li>

                    {/*  <li><Link to="/changelog">Change Log</Link></li>
 */}


                </ul>

                <div className="link-strip">
                    <a href="https://discord.gg/44R2h5gp">
                        <img src="/assets/discordIcon.webp" alt="discord" />
                        <p>
                            Chat on Discord
                        </p>
                    </a>
                </div>

            </nav>



        </>
    )
}

export default Navbar