import { createSlice } from "@reduxjs/toolkit";

const initialState = () => ({
    os:null,
    isWindows: false

})


const osTypeSlice = createSlice({
    name: 'os_type',
    initialState: initialState(),
    reducers: {
        resetOs: state => initialState(),
        setOs: (state, action) => {
            if(action.payload.includes('win')){
                state.isWindows = true
            }
            state.os = action.payload
        }
    }
})

export const { setOs } = osTypeSlice.actions

export default osTypeSlice.reducer